import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu(props) {
    const contactForm = "d1064b86-86f4-4c9d-a678-3aaecd93eda7";

    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <Link to={{
                pathname: `/info`
            }}>
                INFO
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>CONTACT</Link> : null}
        </div>
    );
}

export default TopMenu;
